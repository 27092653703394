<template>
    <div class="imgBox">
        <div class="btnBox">
            <p @click="toAppRoom({roomId:373677})"></p>
            <p @click="toAppRoom({roomId:167367})"></p>
            <p @click="toAppRoom({roomId:168404})"></p>
            <p @click="toAppRoom({roomId:171101})"></p>
        </div>
    </div>
</template>
<script>
export default {
  name: "toRoom",
  data(){
    return{
        client:'iOS'
    }
  },
  created(){
    this.judgeClient();
    document.title = 'Official Rooms'
  },
  methods: { 
    // 跳转房间
    toAppRoom(item){
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
      } else if (this.client == 'Android') {
        nativeJs["languageRoom"](JSON.stringify(item.roomId));
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
  },
};
</script>
<style scope>
.imgBox {
    width: 100vw;
    height: 100vh;
    background-image: url('./bg.png');
    background-repeat: repeat-y;
    background-size: 100% 100%;
}
.btnBox{
    width: 100%;
    height: 1136px;
    z-index: 2;
    background: url('./img.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 300px;
    box-sizing: border-box;
}
.btnBox p{
    margin:0;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
}
</style>